export const PENDING_AFFILIATES_TABLE_KEY = "getPendingAffiliates";
export const CHANGE_HISTORY_TABLE_KEY = "CHANGE_HISTORY_TABLE_KEY";
export const ADVERTISER_DOMAINS_TABLE_KEY = "ADVERTISER_DOMAINS_TABLE_KEY";
export const PENDING_AFFILIATES_TABLE_KEY_ADV = "getPendingAffiliatesAdv";
export const AUTODECLINED_AFFILIATES_TABLE_KEY = "getAutodeclinedAffiliates";
export const ALL_PENDING_AFFILIATES_TABLE_KEY = "getAllPendingAffiliates";
export const RESPONDED_AFFILIATES_TABLE_KEY = "getRespondedAffiliates";
export const ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY = "ADVERTISER_DOMAIN_VALIDATION";
export const ADVERTISER_OFFERS_TABLE_KEY = "ADVERTISER_OFFERS_TABLE_KEY";
export const RESPONDED_AFFILIATES_TABLE_KEY_ADV = "getRespondedAffiliatesAdv";
export const ALL_RESPONDED_AFFILIATES_TABLE_KEY = "getAllRespondedAffiliates";
export const OFFERWALL_TABLE_KEY = "getOfferwall";
export const SMART_OFFER_TABLE_KEY = "SMART_OFFER_TABLE_KEY";
export const PENDING_INT_APPLICATIONS_TABLE_KEY = "getManagerApplications";
export const TOP_OFFERS_TABLE_KEY = "getTopOffers";
export const PENDING_EXT_APPLICATIONS_TABLE_KEY = "PENDING_EXT_APPLICATIONS_TABLE_KEY";
export const PENDING_EXT_TL_APPLICATIONS_TABLE_KEY = "PENDING_EXT_TL_APPLICATIONS_TABLE_KEY";
export const SEARCH_INT_APPLICATIONS_TABLE_KEY = "getOneAffiliateApplications";
export const SEARCH_EXT_APPLICATIONS_TABLE_KEY = "SEARCH_EXT_APPLICATIONS_TABLE_KEY";
export const SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY = "SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY";
export const SEARCH_EXT_APPLICATIONS_AFFILIATE_TABLE_KEY = "SEARCH_EXT_APPLICATIONS_AFFILIATE_TABLE_KEY";
export const SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY = "SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY";
export const SUBRATE_AFFILIATES_TABLE_KEY = "getAffiliateSubrates";
export const INVOICE_HISTORY_AFFILIATES_TABLE_KEY = "INVOICE_HISTORY_AFFILIATES_TABLE_KEY";
export const SUBRATE_AFFILIATES_ADV_TABLE_KEY = "getAffiliateSubratesAdv";
export const AUTODECLINE_RULES_TABLE_KEY = "AUTODECLINE_RULES_TABLE_KEY";
export const OFFER_CAMPAIGNS_TABLE_KEY = "OFFER_CAMPAIGNS_TABLE_KEY";
export const OFFER_CAMPAIGNS_SETUP_TABLE_KEY = "OFFER_CAMPAIGNS_SETUP_TABLE_KEY";
export const ADDITIONAL_POSTBACKS_AFFILIATES_TABLE_KEY = "ADDITIONAL_POSTBACKS_AFFILIATES_TABLE_KEY";
export const POSTBACKS_ADVERTISER_TABLE_KEY = "POSTBACKS_ADVERTISER_TABLE_KEY";
export const INFO_POSTBACKS_AFFILIATES_TABLE_KEY = "INFO_POSTBACKS_AFFILIATES_TABLE_KEY";
export const AFFILIAT_CUSTOM_LINK_TABLE_KEY = "AFFILIAT_CUSTOM_LINK_TABLE_KEY";
export const AFFILIAT_SMART_OFFER_LINK_TABLE_KEY = "AFFILIAT_SMART_OFFER_LINK_TABLE_KEY";
export const PROFILE_MY_AFFILIATES_TABLE_KEY = "findAndListMyAffiliates";
export const PROFILE_AD_NETWORK_TABLE_KEY = "PROFILE_AD_NETWORK_TABLE_KEY";
export const PAYMENT_UNAVAILABLE_MY_AFFILIATES_TABLE_KEY = "getMyAffiliatesWithNoPaymentAvailable";
export const PROFILE_ALL_AFFILIATES_TABLE_KEY = "findAndListAffiliates";
export const PROFILE_ALL_ADVERTISERS_TABLE_KEY = "PROFILE_ALL_ADVERTISERS_TABLE_KEY";
export const PROFILE_MY_ADVERTISERS_TABLE_KEY = "PROFILE_MY_ADVERTISERS_TABLE_KEY";
export const OFFERS_TABLE_KEY = "OFFERS_TABLE_KEY";
export const OFFERS_TABLE_ADV_KEY = "OFFERS_TABLE_ADV_KEY";
export const INT_OFFERS_TABLE_KEY = "INT_OFFERS_TABLE_KEY";
export const EXT_OFFERS_TABLE_KEY = "EXT_OFFERS_TABLE_KEY";
export const INT_OFFERS_ADV_TABLE_KEY = "INT_OFFERS_ADV_TABLE_KEY";
export const INT_OFFERS_AES_TABLE_KEY = "INT_OFFERS_AES_TABLE_KEY";
export const IO_AFFILIATES_TABLE_KEY = "getAffiliateIoList";
export const EXT_RATE_AFFILIATES_TABLE_KEY = "getAffiliateExtRates";
export const EXT_RATE_AFFILIATES_ADV_TABLE_KEY = "getAffiliateExtRatesAdv";
export const SHAVE_MODIFICATIONS_TABLE_KEY = "getShaveModifications";
export const CR_BOOSTER_TABLE_KEY = "getAffiliateBoosterRules";
export const CR_BOOSTER_EXPIRES_DATE_LIMIT = 4;
export const CR_BOOSTER_EXPIRES_DATE_LIMIT_TL = 365;
