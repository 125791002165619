import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@fas/react-scripts";
import App from "./App";

function prepare(): Promise<void> {
  if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_DEV_SW) {
    // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
    const { mockApi } = require("./mocks");// we have to use require instead of import because we don't want to add mocks to the build
    return mockApi.makeServer();
  }
  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(
    <App />,
    document.getElementById("root")
  );
});
