import type React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      retry: false,
    },
  },
});

const overrideTheme = {
  ...theme,
  components: {
    ...theme.components,
    MuiOutlinedInput: {
      styleOverrides: {
        ...theme.components.MuiOutlinedInput,
        root: {
          ...theme.components.MuiOutlinedInput.root,
          "&.Mui-disabled": {
            backgroundColor: "#D4D9E6",
            color: "#8D96AE",
            opacity: 0.5,
          },
          "&.Mui-readOnly": {
            backgroundColor: "#f6f8ff",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabled,
              borderWidth: 1,
            },
          },
        },
      },
    },
  },
};

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={overrideTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}
